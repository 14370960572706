import { Dropdown } from "react-bootstrap";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationsAsync } from "../../redux/notificationSlice.js";
import LoaderComp from "../LoaderComp/index.js";

const Navbar = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.users);
  const { notifications, loading } = useSelector((state) => state.notification);

  useEffect(() => {
    dispatch(getNotificationsAsync);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="navBar">
      <ul className="navBox">
        <li className="navBox-items">
          <i className="fa  fa-user-circle"></i>&nbsp;
          <span className="adminName"> {user && user.name} </span>
        </li>
        <li className="navBox-items">
          <section role="notification">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <div className="navBox-items-notification">
                  <i className="fa fa-bell-o"></i>
                  {loading ? (
                    <LoaderComp />
                  ) : (
                    notifications &&
                    notifications.length > 0 && (
                      <div className="notificationCounter">
                        {notifications && notifications.length}
                      </div>
                    )
                  )}
                </div>{" "}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <section className="notificationWrapper">
                  <h2>Notification</h2>

                  <hr />

                  <ul className="notificationList">
                    <div className="notificationList-day">clear</div>
                    {notifications && notifications.length > 0 ? (
                      <li>
                        <section className="notificationList-text">
                          You received an order from
                          <strong> aroj Kunwar</strong>
                        </section>
                        <div className="notificationList-time">2 min ago</div>
                      </li>
                    ) : (
                      <span>Empty</span>
                    )}
                  </ul>
                </section>
              </Dropdown.Menu>
            </Dropdown>
          </section>
        </li>
      </ul>
    </section>
  );
};

export default Navbar;
