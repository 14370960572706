import React from "react";
import { Pagination } from "react-bootstrap";

const PaginationBox = () => {
  return (
    <div>
      <Pagination>
        <Pagination.Prev children="First" />
        <Pagination.Item>{1}</Pagination.Item>
        <Pagination.Item>{10}</Pagination.Item>
        <Pagination.Item>{11}</Pagination.Item>
        <Pagination.Item active>{12}</Pagination.Item>
        <Pagination.Item>{13}</Pagination.Item>
        <Pagination.Item>{20}</Pagination.Item>

        <Pagination.Last children="Last" />
      </Pagination>
    </div>
  );
};

export default PaginationBox;
