import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getNotificationsAsync = createAsyncThunk(
  "notification/getNotificationAsync",
  async (_, thunkApi) => {
    const { token } = thunkApi.getState().users;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.get("/api/notification", config);
    if (response.status === 200) {
      const notifications = response.data;
      return { notifications };
    }
  }
);

const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    notifications: [],
    loading: false,
  },
  extraReducers: {
    [getNotificationsAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [getNotificationsAsync.fulfilled]: (state, action) => {
      state.notifications = action.payload.notification;
      state.loading = false;
    },
  },
});

export default notificationSlice.reducer;
