import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LoaderComp from "../../components/LoaderComp";

const OrderDetails = () => {
  const options = [
    "DELIVERED",
    "PENDING",
    "PROCESSING",
    "CANCELLED",
    "ORDER_PLACED",
    "DISPATCHED",
    "ON_THE_WAY",
  ];
  const [loading, setLoading] = useState(null);
  const [order, setOrder] = useState(null);
  const [updateSuccess, setUpdateSuccess] = useState(null);
  const [orderStatus, setOrderStatus] = useState(null);
  const { id } = useParams();
  const { token } = useSelector((state) => state.users);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const getOrderDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/orders/${id}`, config);
      response.status === 200 && setOrder(response.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const updateOrderStatus = async () => {
    setLoading(true);
    try {
      const response = await axios.put(
        `/api/orders/${id}/order-status`,
        { orderStatus },
        config
      );
      if (response.status === 200) {
        setUpdateSuccess(true);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    getOrderDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSuccess]);

  useEffect(() => {
    if (orderStatus) updateOrderStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderStatus]);
  const variantInfo = (variant) => {
    const variantKeys = Object.keys(variant);

    if (variantKeys.length === 1) {
      return (
        <div>
          <small>{variantKeys[0]}</small>
          <small>: </small>
          <small>{[variant[variantKeys[0]]]}</small>
        </div>
      );
    }
    return (
      <div>
        <div>
          <span>{variantKeys[0]}</span>
          <span>: </span>
          <span>{[variant[variantKeys[0]]]}</span>
        </div>
        <div>
          <span>{variantKeys[1]}</span>
          <span>: </span>
          <span>{[variant[variantKeys[1]]]}</span>
        </div>
      </div>
    );
  };

  if (loading) return <LoaderComp />;
  return (
    order && (
      <>
        <Container>
          <h1>Order Details</h1>
          <div>
            Details for Order ID:{" "}
            <strong>#{order?.order?._id.toUpperCase()}</strong>
          </div>

          <section className="text-end">
            Order <i className="fa fa-arrow-right"></i> Order Details
          </section>

          <section className="orderDetailsWrapper">
            <Row>
              <section className="flex-between mb-3">
                <div>
                  {moment(order?.order?.createdAt).format("L")}
                  <div className="text-muted">
                    Order ID: {order?.order?._id.toUpperCase()}
                  </div>
                </div>
                <div>
                  <Form.Select
                    aria-label="Default select example"
                    onChange={(e) => setOrderStatus(e.target.value)}
                  >
                    <option>
                      {order?.order?.deliveryStatus?.replaceAll("_", " ")}
                    </option>
                    {options
                      .filter((i) => i !== order?.order?.deliveryStatus)
                      .map((opt) => (
                        <option value={opt} key={opt}>
                          {opt.replaceAll("_", "")}
                        </option>
                      ))}
                  </Form.Select>
                </div>
              </section>
              <hr />
            </Row>

            <Row>
              <Col md={4}>
                <section>
                  <h2>Shipping Address</h2>
                  <ul>
                    <li>
                      Name:{" "}
                      <strong> {order?.order?.shippingDetails?.name} </strong>
                    </li>
                    <li>
                      Email:{" "}
                      <strong> {order?.order?.shippingDetails?.email}</strong>
                    </li>
                    <li>
                      Phone :{" "}
                      <strong>{order?.order?.shippingDetails?.phone} </strong>
                    </li>
                    <li>
                      Address :{" "}
                      <strong>{order?.order?.shippingDetails?.address} </strong>
                    </li>
                    <li>
                      Apartment Number:{" "}
                      <strong>
                        {order?.order?.shippingDetails?.apartmentNumber}
                      </strong>
                    </li>
                    <li>
                      City:{" "}
                      <strong>{order?.order?.shippingDetails?.city}</strong>
                    </li>
                    <li>
                      State/Province:{" "}
                      <strong>{order?.order?.shippingDetails?.state}</strong>
                    </li>
                    <li>
                      Postal Code:{" "}
                      <strong>
                        {order?.order?.shippingDetails?.postalCode}
                      </strong>
                    </li>
                  </ul>
                </section>
              </Col>
              <Col md={4}>
                <section>
                  <h2>Billing Address</h2>
                  <ul>
                    <li>
                      Name:{" "}
                      <strong>{order?.order?.billingDetails?.name}</strong>
                    </li>
                    <li>
                      Email:{" "}
                      <strong>{order?.order?.billingDetails?.email}</strong>
                    </li>
                    <li>
                      Phone :{" "}
                      <strong>{order?.order?.billingDetails?.phone}</strong>
                    </li>
                    <li>
                      Address :{" "}
                      <strong>{order?.order?.billingDetails?.address}</strong>
                    </li>
                    <li>
                      Apartment Number:{" "}
                      <strong>
                        {order?.order?.billingDetails?.apartmentNumber}
                      </strong>
                    </li>

                    <li>
                      City:{" "}
                      <strong>{order?.order?.billingDetails?.city}</strong>
                    </li>
                    <li>
                      State/Province:{" "}
                      <strong>{order?.order?.billingDetails?.state}</strong>
                    </li>
                    <li>
                      Postal Code:{" "}
                      <strong>
                        {order?.order?.billingDetails?.postalCode}
                      </strong>
                    </li>
                  </ul>
                </section>
              </Col>
              <Col md={4}>
                <section>
                  <h2>Order Info</h2>
                  <ul>
                    <li>
                      Ordered By:{" "}
                      <strong>{order?.order?.subscriberId?.name}</strong>
                    </li>
                    <li>
                      Email:{" "}
                      <strong>{order?.order?.subscriberId?.email}</strong>
                    </li>
                    <li>
                      Payment : <strong>{order?.order?.paymentStatus}</strong>
                    </li>
                    <li>
                      Order Status:{" "}
                      <strong>
                        {order?.order?.deliveryStatus.replaceAll("_", " ")}
                      </strong>
                    </li>
                  </ul>
                </section>
              </Col>
            </Row>
          </section>

          <section className="OrderList">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>S.N</th>
                  <th>Product</th>
                  <th>Unit Price</th>
                  <th>Discount</th>
                  <th>Quantity</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {order?.order?.orderItems.map((item, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>
                      <div>
                        <span>{item.productName}</span>
                      </div>
                      <div className="itemInfo-info-brand ">
                        {item.variant ? variantInfo(item.variant) : null}
                      </div>
                    </td>
                    <td>Rs. {parseFloat(item.productPrice).toFixed(2)}</td>
                    <td>{item?.productDiscount}%</td>
                    <td>{item.quantity}</td>
                    <td>
                      Rs.{" "}
                      {parseFloat(
                        item.productPrice * item.quantity - item.discountAmount
                      ).toFixed(2)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            {/* <PaginationBox /> */}

            <section className="order-footer">
              <div className="order-footer-main">
                <Row>
                  <Col md={6}>
                    <span className="text-end">Sub-Total</span>
                  </Col>
                  <Col md={6}>
                    <span>
                      <strong>
                        Rs. {parseFloat(order?.order?.subTotal).toFixed(2)}
                      </strong>
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <span className="text-end">Discount</span>
                  </Col>
                  <Col md={6}>
                    <span>
                      <strong>
                        Rs. {parseFloat(order?.order?.discount).toFixed(2)}
                      </strong>
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <span className="text-end">Shipping Cost</span>
                  </Col>
                  <Col md={6}>
                    <span>
                      <strong>
                        Rs.
                        {parseFloat(order?.order?.shippingPrice || 0).toFixed(
                          2
                        )}
                      </strong>
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <span className="text-end">Grand Total</span>
                  </Col>
                  <Col md={6}>
                    <span>
                      <strong>
                        Rs. {parseFloat(order?.order?.grandTotal).toFixed(2)}
                      </strong>
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <span className="text-end"> Payment Status</span>
                  </Col>
                  <Col md={6}>
                    <span>
                      <strong>{order?.order?.paymentStatus}</strong>
                    </span>
                  </Col>
                </Row>
              </div>
            </section>
          </section>

          <h1 className="text-center mt-4 cse">Track Order</h1>
          <section className="trackerWrapper">
            <div>
              <i
                className="fa fa-shopping-bag"
                style={{
                  background:
                    order?.order?.deliveryStatus === "ORDER_PLACED" && "green",
                }}
              ></i>
              <div>Order Placed</div>
            </div>
            <div>
              <i
                style={{
                  background:
                    order?.order?.deliveryStatus === "PROCESSING" && "green",
                }}
                className="fa fa-hourglass-half"
              ></i>
              <div>Processing Order</div>
            </div>
            <div>
              <i
                style={{
                  background:
                    order?.order?.deliveryStatus === "DISPATCHED" && "green",
                }}
                className="fa fa-truck"
              ></i>
              <div>Product Dispatched</div>
            </div>
            <div>
              <i
                className="fa fa-check-square-o"
                style={{
                  background:
                    order?.order?.deliveryStatus === "DELIVERED" && "green",
                }}
              ></i>
              <div>Product Delivered</div>
            </div>
          </section>
        </Container>
      </>
    )
  );
};

export default OrderDetails;
