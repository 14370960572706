import React from "react";
import { Container, Form, Table } from "react-bootstrap";
import PaginationBox from "../../components/PaginatonBox";

const Transactions = () => {
  return (
    <>
      <Container>
        <section>
          <h1>Transactions</h1>
        </section>

        <section className="transactionWrapper">
          <section className="transFilter">
            <Form.Select aria-label="Default select example">
              <option>Method</option>
              <option value="1">stock low to high</option>
              <option value="2">stock high to low</option>
              <option value="3">ascending</option>
              <option value="3">decending</option>
            </Form.Select>
            <input type="date" />
          </section>

          <section className="transTable">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Address</th>
                  <th>Method</th>
                  <th>Date</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Sample Name</td>
                  <td>sample long address</td>
                  <td>Stripe</td>
                  <td>22-20-2020 2:28 p.m</td>
                  <td>$4654</td>
                </tr>
                <tr>
                  <td>Sample Name</td>
                  <td>sample long address</td>
                  <td>Stripe</td>
                  <td>22-20-2020 2:28 p.m</td>
                  <td>$4654</td>
                </tr>
                <tr>
                  <td>Sample Name</td>
                  <td>sample long address</td>
                  <td>Stripe</td>
                  <td>22-20-2020 2:28 p.m</td>
                  <td>$4654</td>
                </tr>
                <tr>
                  <td>Sample Name</td>
                  <td>sample long address</td>
                  <td>Stripe</td>
                  <td>22-20-2020 2:28 p.m</td>
                  <td>$4654</td>
                </tr>
                <tr>
                  <td>Sample Name</td>
                  <td>sample long address</td>
                  <td>Stripe</td>
                  <td>22-20-2020 2:28 p.m</td>
                  <td>$4654</td>
                </tr>
              </tbody>
            </Table>

            <PaginationBox />
          </section>
        </section>
      </Container>
    </>
  );
};

export default Transactions;
