import axios from "axios";
import React from "react";
import moment from "moment";
import { Container, Form, Table } from "react-bootstrap";
import OrderStatus from "../../components/OrderStatus";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  filterGuestAsync,
  filterWithStatusAsync,
  getOrderListAsync,
} from "../../redux/orderSlice";
import Paginate from "../../components/Paginate";
import Badge from "react-bootstrap/Badge";
import { useState } from "react";

const OrderPage = () => {
  const [counts, setCounts] = useState(null);
  const { user, token } = useSelector((state) => state.users);
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const getOverview = async () => {
    const response = await axios.get("/api/orders/overview", config);
    if (response.status === 200) {
      setCounts(response.data);
    }
  };

  const [status, setStatus] = useState("0");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { pageNumber } = useParams();
  pageNumber = pageNumber || 1;
  let { orders, pages, page } = useSelector((state) => state.orders);
  const filterOrderHistory = (e) => {
    if (e.target.value === "0") {
      setStatus(null);
    } else {
      setStatus(e.target.value);
    }
  };
  useEffect(() => {
    dispatch(getOrderListAsync({ pageNumber }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);
  useEffect(() => {
    if (status && status === "GUEST") {
      dispatch(filterGuestAsync({ status, pageNumber }));
    } else if (status && status !== "0") {
      dispatch(filterWithStatusAsync({ status, pageNumber }));
    } else {
      dispatch(getOrderListAsync({ pageNumber }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    getOverview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleProductNavigation = (id) => {
    navigate(`/order-list/order-details/${id}`);
  };

  return (
    <>
      {counts && <OrderStatus counts={counts} />}

      <section className="OrderPageWrapper">
        <Container>
          <section>
            <h2>Order Lists</h2>

            <section className="text-muted">All your Order Lists Here</section>
          </section>

          <section className="flex-between actionBar">
            <Form.Select
              value={status}
              aria-label="Default select example"
              onChange={(e) => filterOrderHistory(e)}
            >
              <option value="0">All</option>
              <option value="ORDER_PLACED">Placed</option>
              <option value="PROCESSING">Processing</option>
              <option value="DISPATCHED">Dispatched</option>
              <option value="ON_THE_WAY">On The Way</option>
              <option value="PENDING">Pending</option>
            </Form.Select>
            <button
              className="bton bton--nacked bton--sm"
              onClick={() => setStatus("0")}
            >
              clear Filter
            </button>
          </section>

          <section>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>S.no</th>
                  <th>Customer</th>
                  <th>Email/Phone</th>
                  <th>Items</th>
                  <th>Price</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Manage</th>
                </tr>
              </thead>
              <tbody>
                {orders?.map((order, i) => (
                  <tr
                    key={order._id}
                    onClick={() => handleProductNavigation(order._id)}
                    className="cpntr"
                  >
                    <td>{i + 1}</td>
                    <td>
                      {order.subscriberId
                        ? order.subscriberId?.name
                        : order.subscriberPhone && !order.subscriberEmail
                        ? "WHATSAPP"
                        : order.subscriberPhone && order.subscriberEmail
                        ? "EMAIL"
                        : "GUEST CHECKOUT"}
                    </td>
                    <td>
                      {order.subscriberId
                        ? order.subscriberId?.email
                        : order.subscriberPhone && !order.subscriberEmail
                        ? order.subscriberPhone
                        : order.subscriberPhone && order.subscriberEmail
                        ? `${order.subscriberPhone} / ${order.subscriberEmail} `
                        : "GUEST CHECKOUT"}
                    </td>
                    <td>{order.orderItems?.length}</td>
                    <td>Rs. {parseFloat(order.grandTotal).toFixed(2)}</td>
                    <td>{moment(order.createdAt).format("L")}</td>
                    <td>
                      <div>
                        <Badge bg="primary">
                          {order.deliveryStatus.replaceAll("_", " ")}
                        </Badge>
                      </div>
                    </td>
                    <td>
                      <section className="p-2 ">
                        <Link to={`/order-list/order-details/${order._id}`}>
                          <i
                            className="fa  fa-sliders cpr "
                            style={{ fontSize: "1.5rem" }}
                          ></i>
                        </Link>
                      </section>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            {orders && (
              <Paginate
                pages={pages}
                page={page}
                isAdmin={true}
                list="order-list"
                navigate={navigate}
              />
            )}
          </section>
        </Container>
      </section>
    </>
  );
};

export default OrderPage;
