import { Form } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userLoginAsync } from "../../redux/userSlice.js";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isError, token, user } = useSelector((state) => state.users);
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [messageText, setErrorMessageText] = useState("");
  const [validationError, setValidationError] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [emptyError, setEmptyError] = useState(null);
  const loginHandler = async (e) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    e.preventDefault();
    if (email === "" || password === "") {
      setEmptyError(true);
      setErrorMessageText("The fields can not be empty!");
      return;
    } else if (!emailPattern.test(email)) {
      setEmailError(true);
      setErrorMessageText("Please enter a valid email address");
      return;
    } else {
      dispatch(userLoginAsync({ email, password }));
    }
  };
  useEffect(() => {
    if (token && user) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  useEffect(() => {
    if (isError) {
      setValidationError(true);
      setErrorMessageText("Invalid Email or Password !");
    }
  }, [isError]);
  return (
    <div className="loginBack">
      <section className="LoginWrapper">
        <div className="text-center">
          {/* <img src={BrandLogo} alt='' /> */}
          <h1 className="mt-3">Login</h1>
        </div>

        <Form onSubmit={loginHandler}>
          <Form.Group className="mb-3 mt-4" controlId="formBasicEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3 mt-4" controlId="formBasicEmail">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type={show ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-2" controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              checked={show}
              onChange={(e) => setShow(e.target.checked)}
              label="showPassword"
            />
          </Form.Group>

          <div style={{ height: "1.7rem" }}>
            {(validationError || emailError || emptyError) && (
              <div
                className="cda"
                success={false}
                setError={
                  validationError
                    ? setValidationError
                    : emailError
                    ? setEmailError
                    : setEmptyError
                }
              >
                {messageText}
              </div>
            )}
          </div>
          <button className="bton bton--primary bton--full" type="submit">
            Login
          </button>
        </Form>
      </section>
    </div>
  );
};

export default Login;
