import React, { useCallback, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { CategorySortCard } from "./CategorySortCard";
import update from "immutability-helper";
import { useDispatch } from "react-redux";
import { sortCategoryAsync } from "../../redux/categorySlice";

const SortCategories = ({ showBox, data }) => {
  const [categories, setCategories] = useState(data);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setCategories((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  }, []);
  const renderCard = useCallback((card, index) => {
    return (
      <CategorySortCard
        key={card._id}
        index={index}
        id={card._id}
        text={card.name}
        moveCard={moveCard}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDone = async () => {
    const data = {
      categories: categories,
    };
    setLoading(true);
    try {
      await dispatch(sortCategoryAsync(data)).unwrap();
      showBox(false);
    } catch (err) {
      // CatcHERR
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="sort-popup">
        <div className="sort-popup_overlay" onClick={() => showBox(false)} />
        <div className="sort-cat">
          <div className="sort-cat_header">
            <span>Sort Categories</span>
            <button onClick={() => showBox(false)}>
              <i className="fa fa-close"></i>
            </button>
          </div>
          <small>
            This will sort the order in which the categories are displayed in
            the store navbar.
          </small>
          <div className="sort-cat_main">
            <div>{categories.map((card, i) => renderCard(card, i))}</div>
          </div>
          <div className="sort-cat_btns">
            <button
              type="button"
              className="bton bton--sm bton--nacked"
              onClick={() => showBox(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="bton bton--sm bton--primary"
              onClick={handleDone}
              disabled={loading}
            >
              {!loading ? "Done" : "..."}
            </button>
          </div>
          {error ? <small className="cda">{error}</small> : null}
        </div>
      </div>
    </DndProvider>
  );
};

export default SortCategories;
