import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import {
  loginWithToken,
  removeLoginWithTokenMessage,
} from "../redux/userSlice";
import LoaderComp from "../components/LoaderComp";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import BrandLogo from "../assets/images/brand_image.svg";

const RequireAuth = () => {
  const dispatch = useDispatch();
  const { token, user, tokenStatus } = useSelector((state) => state.users);
  const location = useLocation();

  useEffect(() => {
    if (token && !user) {
      dispatch(loginWithToken());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (tokenStatus === "failed") {
    dispatch(removeLoginWithTokenMessage());
    return <Navigate to="/login" replace />;
  }

  if (tokenStatus === "loading" || (token && !user)) {
    return <LoaderComp />;
  }

  return token && user ? (
    <section className="projectWrapper">
      <aside className="sidebarWrapper">
        <div className="sidebarWrapper-brand">
          <img src={BrandLogo} alt="" />
        </div>
        <hr />
        <Sidebar />
      </aside>
      <section className="contentWrapper">
        <Navbar />
        <Outlet />
      </section>
    </section>
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};

export default RequireAuth;
