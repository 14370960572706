import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import OrderStatus from "../../components/OrderStatus";
import { getSalesChartAsync } from "../../redux/orderSlice";
import DemandSupplyChart from "../../components/DemandSupplyChart";
import LoaderComp from "../../components/LoaderComp";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { isLoading, salesChart, isSalesChartLoading } = useSelector(
    (state) => state.orders
  );

  useEffect(() => {
    if (!salesChart || salesChart.length === 0) {
      dispatch(getSalesChartAsync());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <LoaderComp />;

  return (
    <>
      <section className="dashboardWrapper">
        <h1>Current order status</h1>
        <OrderStatus />
      </section>
      <section className="salesWrapper">
        <h1>Sales Details</h1>
        {isSalesChartLoading ? (
          <p>Loading chart...</p>
        ) : (
          <DemandSupplyChart
            placed={salesChart.placed}
            success={salesChart.success}
          />
        )}
      </section>
    </>
  );
};

export default Dashboard;
